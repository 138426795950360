import { FC, useRef, useState } from "react";
import styles from "./styles.module.scss";
import {
  CheckCircleIcon,
  DotsVertical,
  ImageIcon,
  NestedIcon,
  PageIcon,
  PencilIcon,
  TrashIcon,
} from "../../assets";
import { motion } from "framer-motion";
import { useOutsideClick } from "../../hooks";
import {
  FaqItem,
  useDeleteTopLevelFaqSection,
  useUpdateTopLevelFaq,
} from "../../services/faq.service";
import clsx from "clsx";
import { DeleteFaqSection, EditFaqSection } from "../../modals";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

type FaqCardProps = {
  item: FaqItem;
  isSelected: boolean;
  onClick: (item: FaqItem) => void;
};

const FaqCard: FC<FaqCardProps> = ({ item, isSelected, onClick }) => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [isVisibleEditModal, setIsVisibleEditModal] = useState(false);

  const { mutateAsync: updateTopLevelFaq } = useUpdateTopLevelFaq();

  const onUpdateTopLevelSection = async ({
    status,
  }: {
    status: "draft" | "published";
  }) => {
    try {
      const res = await updateTopLevelFaq({
        id: item?.id,
        status,
      });
      if (res?.success) {
        queryClient.invalidateQueries({ queryKey: ["top-level-faq"] });

        await queryClient.invalidateQueries({
          queryKey: ["faq-item", item?.parentId],
        });
        await queryClient.invalidateQueries({
          queryKey: ["faq-item", item?.id],
        });
      }

      toast.success("Successfully updated");
    } catch (error) {
      console.log("error", error);
    }
  };

  const queryClient = useQueryClient();

  const {
    mutateAsync: mutateDeleteTopLevelFaqSection,
    isPending: isPendingDelete,
  } = useDeleteTopLevelFaqSection();

  useOutsideClick(ref, () => setIsOpenMenu(false));

  const handleDelete = async () => {
    await mutateDeleteTopLevelFaqSection(item.id);
    if (!item.parentId) {
      await queryClient.invalidateQueries({ queryKey: ["top-level-faq"] });
    } else {
      await queryClient.invalidateQueries({
        queryKey: ["faq-item", item.parentId],
      });
    }
  };

  return (
    <div style={{ position: "relative" }} ref={ref}>
      <DeleteFaqSection
        isOpen={isVisibleDeleteModal}
        setIsOpen={setIsVisibleDeleteModal}
        onDelete={handleDelete}
        isLoading={isPendingDelete}
        title={
          item.type === "section" ? "Delete nested section?" : "Delete article?"
        }
        subtitle={`This action cannot be undone and will delete all nested elements inside it.`}
      />
      <EditFaqSection
        isVisible={isVisibleEditModal}
        item={item}
        onClose={() => setIsVisibleEditModal(false)}
      />
      <DotsVertical
        className={styles.dotsVertical}
        onClick={(e) => {
          setIsOpenMenu((prev) => !prev);
        }}
      />
      <div
        onClick={() => onClick(item)}
        className={clsx(styles.wrapper, {
          [styles.wrapperActive]: isSelected,
        })}
      >
        {item?.image ? <img src={item.image} alt="faq" /> : <ImageIcon />}
        <div className={styles.contentContainer}>
          <p>{item.title}</p>
          {item.type === "section" ? (
            <div className={styles.nestedType}>
              <NestedIcon />
              <span>Nested</span>
            </div>
          ) : item.type === "article" && item?.status === "published" ? (
            <div className={styles.articleType}>
              <PageIcon />
              <span>Article</span>
            </div>
          ) : (
            <div className={styles.draftType}>
              <PageIcon />
              <span>Draft</span>
            </div>
          )}
        </div>

        {isOpenMenu && (
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className={styles.menuWrapper}
          >
            {item?.type === "article" ? (
              item?.status === "published" ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onUpdateTopLevelSection({ status: "draft" });
                    setIsOpenMenu(false);
                  }}
                  className={styles.menuButton}
                >
                  <PageIcon
                    className={styles.draftIcon}
                    width={20}
                    height={20}
                  />
                  <span style={{}}>Draft</span>
                </div>
              ) : (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onUpdateTopLevelSection({ status: "published" });
                    setIsOpenMenu(false);
                  }}
                  className={styles.menuButton}
                >
                  <CheckCircleIcon
                    className={styles.publishIcon}
                    width={20}
                    height={20}
                  />
                  <span className={styles.publishButton}>Publish</span>
                </div>
              )
            ) : null}
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsVisibleEditModal(true);
                setIsOpenMenu(false);
              }}
              className={styles.menuButton}
            >
              <PencilIcon className={styles.editIcon} width={20} height={20} />
              <span style={{}}>Edit</span>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsVisibleDeleteModal(true);
                setIsOpenMenu(false);
              }}
              className={styles.menuButton}
            >
              <TrashIcon className={styles.deleteIcon} width={20} height={20} />
              <span style={{ color: "#EE3758" }}>Delete</span>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default FaqCard;
