import { FaqSection } from "../../../components";
import styles from "./styles.module.scss";
import { useGetTopLevelFaq } from "../../../services/faq.service";
import { PulseLoader } from "react-spinners";
import { useRef } from "react";
import { ArrowDownIcon } from "../../../assets";
import clsx from "clsx";

const FAQ = () => {
  const { data, isLoading: isLoadingTopLevelFaq } = useGetTopLevelFaq();

  const scrollRef = useRef<HTMLDivElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToRight = () => {
    if (scrollRef.current && containerRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft + containerRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const scrollToLeft = () => {
    if (scrollRef.current && containerRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft - containerRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  if (isLoadingTopLevelFaq) {
    return (
      <div className={styles.loader}>
        <PulseLoader color={"#7D4AFB"} />
      </div>
    );
  }

  if (data) {
    return (
      <div ref={containerRef} className={styles.wrapper}>
        <header className={styles.header}>
          <h4 className={styles.title}>FAQ</h4>
          <div className={styles.buttonsContainer}>
            <div
              onClick={scrollToLeft}
              className={clsx(styles.scrollLeftButton, {
                // TODO

                [styles.scrollButtonDisabled]: false,
              })}
            >
              <ArrowDownIcon />
            </div>
            <div
              onClick={scrollToRight}
              className={clsx(styles.scrollRightButton, {
                // TODO
                [styles.scrollButtonDisabled]: false,
              })}
            >
              <ArrowDownIcon />
            </div>
          </div>
        </header>

        <div ref={scrollRef} className={styles.contentContainer}>
          <FaqSection title="Home" items={data} id={null} />
        </div>
      </div>
    );
  }

  return null;
};

export default FAQ;
