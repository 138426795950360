import { FC } from "react";
import Modal from "react-modal";
import { CrossIcon } from "../../assets";
import { Button } from "../../components";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onDelete: () => void;
  isLoading: boolean;
  title: string;
  subtitle: string;
}

const DeleteFaqSection: FC<Props> = ({
  isOpen,
  setIsOpen,
  onDelete,
  isLoading,
  title,
  subtitle,
}): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <CrossIcon
          onClick={() => setIsOpen(false)}
          className={styles.closeBtn}
        />
        <h6>{title}</h6>
        <p>{subtitle}</p>
        <div className={styles.btnContainer}>
          <Button
            title={"Confirm"}
            onClick={onDelete}
            loading={isLoading}
            size={"small"}
            styleType="filled"
            backgroundColor="#FF3C3C"
          />
          <Button
            onClick={() => setIsOpen(false)}
            title={"Cancel"}
            size={"small"}
            styleType="disabled"
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteFaqSection;
