import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../../components";
import styles from "./styles.module.scss";

const Dashboard: FC = () => {
  return (
    <div className={styles.wrapper}>
      <Sidebar />
      <div className={styles.contentContainer}>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
