import { FC } from "react";
import FaqPreviewImage from "../../assets/images/faq-preview.png";

import styles from "./styles.module.scss";
import FaqAccordionPreview from "../FaqAccordionPreview";

type FaqPreviewProps = {
  faqContent: Array<{
    id: string;
    index: number;
    type: "text" | "accordion";
    title: string;
    html: string;
    image?: string;
  }>;
};

const FaqPreview: FC<FaqPreviewProps> = ({ faqContent }) => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.background} src={FaqPreviewImage} alt="faq" />
      <div className={styles.contentContainer}>
        {faqContent?.map((item, index) => {
          if (item.type === "text") {
            return (
              <div key={index} className={"html-wrapper"}>
                <div
                  className={styles.descriptionWrapper}
                  dangerouslySetInnerHTML={{
                    __html: item?.html,
                  }}
                />
              </div>
            );
          }

          if (item.type === "accordion") {
            return (
              <FaqAccordionPreview
                key={index}
                title={item.title}
                content={item.html}
                icon={item.image}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default FaqPreview;
