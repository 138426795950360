import Navigation from "./navigation";
import { Toaster } from "react-hot-toast";

import { CheckCircleIcon, CrossCircleIcon } from "./assets";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const fifth_minutes = 5 * (60 * 1000);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: false,
      staleTime: fifth_minutes,
      retryDelay: 5000,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Navigation />
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "rgba(213, 244, 211, 0.8)",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 22,
              paddingRight: 22,
              borderRadius: 10,
              fontSize: 14,
              fontFamily: "Inter",
              textAlign: "left",
              maxWidth: 1000,
            },
            icon: <CheckCircleIcon style={{ fill: "green" }} />,
          },
          error: {
            style: {
              background: "rgba(254, 206, 206, 0.8)",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 22,
              paddingRight: 22,
              borderRadius: 10,
              fontSize: 14,
              fontFamily: "Inter",
              textAlign: "left",
              maxWidth: 1000,
            },
            icon: <CrossCircleIcon />,
          },
        }}
      />
    </QueryClientProvider>
  );
}

export default App;
