import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isoWeek from "dayjs/plugin/isoWeek";
import isToday from "dayjs/plugin/isToday";
import duration from "dayjs/plugin/duration";
import { moveElementInArray } from "./methods";

export { moveElementInArray };

dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(isToday);
dayjs.extend(duration);

export const openLink = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

export const getDaysOfWeek = (fromDate = new Date()) => {
  const currentWeek = dayjs(fromDate);
  let start = dayjs(currentWeek).utc().startOf("isoWeek");
  const end = dayjs(currentWeek).utc().endOf("isoWeek");

  const days: string[] = [];

  while (!end.isBefore(start, "day")) {
    days.push(start.toISOString().split("T")[0]);
    start = start.add(1, "day");
  }

  return days;
};

export const getDaysOfMonth = (month: number, year: number) => {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const fileDownloader = async (url: string, fileName: string) => {
  await axios({
    url: url,
    method: "GET",
    headers: {
      "Content-Type": "",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      return response;
    })
    .catch((e) => e);
};
