import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import {
  ArrowDownIcon,
  ArrowLineIcon,
  PlusIcon,
  RecipeIcon,
  TrashIcon,
} from "../../../assets";
import {
  Button,
  ConfigurableIngredient,
  Input,
  SearchIngredients,
  Selector,
  TagSelector,
  TextArea,
} from "../../../components";
import { ICategory, ITags } from "../../../models";
import { useNavigate } from "react-router-dom";
import { GoogleImagesSearch, RecipePreview } from "../../../modals";
import { IConfigurableIngredient } from "./types";
import {
  createRecipe,
  getLinkForUploadRecipeImage,
  getTags,
} from "../../../services/recipe.service";
import { uploadFile } from "../../../services/file.service";
import { toast } from "react-hot-toast";
import { PulseLoader } from "react-spinners";
import { getCategories } from "../../../services/category.service";

const CreateRecipe: FC = (): JSX.Element => {
  const [recipeTitle, setRecipeTitle] = useState("");
  const [recipeCategory, setRecipeCategory] = useState<string>("");
  const [recipeDescription, setRecipeDescription] = useState("");
  const [ingredients, setIngredients] = useState<IConfigurableIngredient[]>([]);
  const [isShowSearchIngredients, setIsShowSearchIngredients] = useState(false);
  const [preparations, setPreparations] = useState<string[]>([]);
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
  const [allTags, setAllTags] = useState<ITags[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);

  const [imagePath, setImagePath] = useState("");
  const [imageFile, setImageFile] = useState<any>("");
  const [imageFileName, setimageFileName] = useState("");
  const [isShowSearchWebImages, setIsShowSearchWebImages] = useState(false);
  const [gramsPerServe, setGramsPerServe] = useState<string>("");
  const [gramsPerServeUnit, setGramsPerServeUnit] = useState<string>("");
  const [numbersOfServe, setNumbersOfServe] = useState<string>("1");
  const navigation = useNavigate();

  const isDisabled =
    recipeTitle.length < 2 ||
    (recipeDescription && recipeDescription.length < 2) ||
    !ingredients.length ||
    !selectedTags.length ||
    selectedTags.length > 10 ||
    !numbersOfServe ||
    !!(gramsPerServe && !gramsPerServeUnit);

  const onGetCategories = async () => {
    try {
      setIsLoadingCategories(true);
      const { data } = await getCategories("");
      if (
        data?.success &&
        data?.data?.categories &&
        data?.data?.categories?.length > 0
      ) {
        setCategories(data?.data?.categories);
        setRecipeCategory(data?.data?.categories[0].value);
      }
    } catch (error) {
    } finally {
      setIsLoadingCategories(false);
    }
  };

  useEffect(() => {
    onGetCategories();
  }, []);

  const onChangeRecipeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipeTitle(e.target.value);
  };

  const onChangeRecipeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRecipeDescription(e.target.value);
  };

  const onChangeGramsPerServe = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setGramsPerServe("");
    } else {
      setGramsPerServe(parseInt(e.target.value).toString());
    }
  };

  const onChangeNumbersOfServe = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setNumbersOfServe("");
    } else {
      const validated = e.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/);
      if (validated) {
        setNumbersOfServe(parseFloat(e.target.value).toString());
      }
    }
  };
  const onChangePreparationMethod = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setPreparations((prev) => {
      const copy = [...prev];
      copy[index] = e.target.value;
      return copy;
    });
  };

  const onDeletePreparationMethod = (index: number) => {
    setPreparations((prev) => {
      const copy = [...prev];
      copy.splice(index, 1);
      return copy;
    });
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      const type = e.target.files[0].type.slice(0, 5);

      if (type !== "image") {
        toast.error("Only pictures can be uploaded here");
        return;
      }
      setImagePath(URL.createObjectURL(e.target.files[0]));
      let file = e.target.files[0];
      setImageFile(e.target.files[0]);
      setimageFileName(file?.name);
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteImage = () => {
    setimageFileName("");
    setImageFile("");
    setImagePath("");
  };

  const onGetTags = async () => {
    try {
      setIsLoadingTags(true);
      const { data } = await getTags("");
      if (data?.success) {
        setAllTags(data?.data?.tags);
      }
    } catch (error) {
    } finally {
      setIsLoadingTags(false);
    }
  };

  const convertIngredients = (
    ingredient: IConfigurableIngredient
  ): IConfigurableIngredient => {
    let k;

    const ingredeintMacro = ingredient.units.find(
      (item) => item.name === ingredient.unit
    );
    if (ingredeintMacro) {
      ingredient = {
        ...ingredient,
        id: ingredient.id,
        name: ingredient.name,
        category: ingredient.category,
        //@ts-ignore
        proteins: +(ingredeintMacro?.proteins * ingredient.count).toFixed(2),
        //@ts-ignore

        fats: +(ingredeintMacro?.fats * ingredient.count).toFixed(2),
        //@ts-ignore

        carbs: +(ingredeintMacro?.carbs * ingredient.count).toFixed(2),
        //@ts-ignore

        fiber: +(ingredeintMacro?.fiber * ingredient.count).toFixed(2),

        //@ts-ignore
        calories: +(ingredeintMacro?.calories * ingredient.count).toFixed(2),
      };
    }

    return { ...ingredient };
  };

  const onCreateRecipe = async () => {
    try {
      setIsLoadingSave(true);
      let imageLink = imagePath;
      if (imageFile) {
        const imageRes = await getLinkForUploadRecipeImage(imageFileName);
        imageLink = imageRes?.data?.data?.downloadUrl;
        let blob = new Blob([imageFile], { type: imageFile.type });

        await uploadFile(blob, imageRes?.data?.data?.uploadUrl);
      }

      const { data } = await createRecipe(
        recipeTitle,
        selectedTags.map(
          (item) => allTags.find((tag) => tag.value === item)?.id || 0
        ),
        categories.find((item) => item.value === recipeCategory)?.id || 0,
        preparations
          ?.map((item) => item?.trim())
          .filter((item) => item?.length > 0),
        ingredients.map((item) => ({
          ingredientId: item.id,
          count: +item.count,
          unit: item.unit,
        })),
        recipeDescription,
        imageLink,
        +numbersOfServe,
        +gramsPerServe,
        gramsPerServeUnit
      );
      if (data?.success) {
        toast.success("Meal has been successfully created");
        navigation(-1);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error?.message?.[0]?.message || "Something went wrong");
    } finally {
      setIsLoadingSave(false);
    }
  };

  useEffect(() => {
    onGetTags();
  }, []);

  const calculateMacro = (ingredients: IConfigurableIngredient[]) => {
    if (!ingredients) {
      return {
        cal: 0,
        Protein: 0,
        Fat: 0,
        Carbs: 0,
        Fiber: 0,
        "P/E": 0,
      };
    }
    const macros = ingredients?.reduce(
      (macro: any, i: any) => {
        const iMacro = i.units?.find((item: any) => item.name === i.unit);
        macro.proteins += +(iMacro?.proteins * i.count).toFixed(2);
        macro.fats += +(iMacro?.fats * i.count).toFixed(2);
        macro.carbs += +(iMacro?.carbs * i.count).toFixed(2);
        macro.fiber += +(iMacro?.fiber * i.count).toFixed(2);
        macro.calories += +(iMacro?.calories * i.count).toFixed(2);
        return macro;
      },
      {
        proteins: 0,
        fats: 0,
        carbs: 0,
        fiber: 0,
        calories: 0,
      }
    );

    macros["P/E"] = !macros["P/E"] ? 0 : macros["P/E"] / ingredients?.length;
    const macro = {
      cal: macros?.calories,
      Protein: macros?.proteins,
      Fat: macros?.fats,
      Carbs: macros?.carbs,
      Fiber: macros?.fiber,
      "P/E": !macros?.calories
        ? 0
        : ((macros?.proteins * 4) / macros?.calories) * 100 || 0,
    };

    return macro;
  };

  return (
    <>
      <RecipePreview
        isOpen={isShowPreview}
        imagePath={imagePath}
        title={recipeTitle}
        category={recipeCategory}
        ingredients={ingredients}
        description={recipeDescription}
        tags={selectedTags}
        preparations={preparations}
        setIsOpen={setIsShowPreview}
        numberOfServes={numbersOfServe}
        gramsPerServe={gramsPerServe}
        gramsPerServeUnit={gramsPerServeUnit}
      />
      <GoogleImagesSearch
        isOpen={isShowSearchWebImages}
        setIsOpen={setIsShowSearchWebImages}
        setImagePath={setImagePath}
        setImageFile={setImageFile}
      />
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <ArrowLineIcon
            onClick={() => navigation(-1)}
            className={styles.headerArrowButton}
          />
          <h5 className={styles.headerTitle}>Create New Recipe</h5>
        </header>
        <section className={styles.recipeInfoContainer}>
          <div className={styles.recipeInfoBlock}>
            <Input
              value={recipeTitle}
              onChange={onChangeRecipeTitle}
              label="Meal Title *"
              placeholder="Meal Title"
              maxLength={100}
            />
            <Selector
              value={recipeCategory}
              setValue={setRecipeCategory}
              data={categories.map((item) => item.value)}
              label="Category *"
            />
            <TextArea
              value={recipeDescription}
              onChange={onChangeRecipeDescription}
              label="Description"
              placeholder="Description"
              maxLength={500}
            />
          </div>
          <div className={styles.recipeImageBlock}>
            <div className={styles.imageContainer}>
              {imagePath ? (
                <img src={imagePath} className={styles.image} />
              ) : (
                <RecipeIcon className={styles.recipeIcon} />
              )}
            </div>
            <div className={styles.buttonsContainer}>
              <label
                htmlFor={"photoInput"}
                className={styles.uploadImageButton}
              >
                Upload New
                <input
                  id={"photoInput"}
                  type={"file"}
                  accept="image/*"
                  onChange={onSelectFile}
                  //@ts-ignore
                  onClick={(e) => (e.target.value = null)}
                  className={styles.uploadImageInput}
                />
              </label>
              <div onClick={onDeleteImage} className={styles.deleteImageButton}>
                Delete Photo
              </div>

              <div
                onClick={() => setIsShowSearchWebImages(true)}
                className={styles.deleteImageButton}
              >
                Upload from Web
              </div>
            </div>
          </div>
        </section>
        <div className={styles.sectionDivider} />
        <h5 className={styles.sectionTitle}>Serving</h5>
        <div className={styles.servingContainer}>
          <Input
            placeholder="Meal Weight"
            label="Meal Weight"
            value={gramsPerServe}
            onChange={onChangeGramsPerServe}
            type="number"
          />
          <Selector
            label={gramsPerServe ? "Unit *" : "Unit"}
            data={["grams", "oz", "fl oz"]}
            value={gramsPerServeUnit}
            setValue={setGramsPerServeUnit}
            wrapperStyles={styles.unitSelector}
          />
          <Input
            placeholder="Servings *"
            label="Servings *"
            value={numbersOfServe}
            onChange={onChangeNumbersOfServe}
            type="number"
          />
          <div className={styles.macroWrapper}>
            <div className={styles.macroSectionTitle}>Energy per serving:</div>
            <div className={styles.macroContainer}>
              {Object.entries(calculateMacro(ingredients)).map(
                (item, index) => (
                  <div key={index} className={styles.macroItem}>
                    <span className={styles.macroValue}>
                      {
                        //@ts-ignore
                        (
                          item[1] /
                          (item[0] === "P/E" ? 1 : +numbersOfServe || 1)
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: item[0] === "cal" ? 0 : 1,
                        })
                      }
                      {item[0] !== "cal"
                        ? item[0] === "P/E"
                          ? "%"
                          : " g"
                        : ""}
                    </span>
                    <span className={styles.macroTitle}>{item[0]}</span>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        <div className={styles.sectionDivider} />
        <h5 className={styles.sectionTitle}>Ingredients *</h5>
        {ingredients.length > 0 && (
          <div className={styles.ingredientListHeader}>
            <div className={styles.infoContainer}>
              <span className={styles.name}>Ingredient</span>
              <span className={styles.amount}>Amount</span>
              <span className={styles.unit}>Unit</span>
            </div>
            <div className={styles.macroContainer}>
              <span className={styles.calories}>Calories</span>
              <span className={styles.macroItem}>Protein</span>
              <span className={styles.macroItem}>Fat</span>
              <span className={styles.macroItem}>Carbs</span>
              <span className={styles.macroItem}>Fiber</span>
              <span className={styles.macroItem}>Alcohol</span>
            </div>
          </div>
        )}
        <div className={styles.ingredientList}>
          {ingredients.map((item, index) => (
            <ConfigurableIngredient
              item={convertIngredients(item)}
              setIngredients={setIngredients}
              index={index}
              key={`${item.id}_${index}`}
            />
          ))}
        </div>
        <div className={styles.addIngredeintButtonContainer}>
          <div
            ref={searchRef}
            onClick={() => setIsShowSearchIngredients(true)}
            className={styles.addButton}
          >
            <PlusIcon />
            <span className={styles.title}>Add Ingredient</span>
            {isShowSearchIngredients && (
              <SearchIngredients
                blockRef={searchRef}
                setIsShow={setIsShowSearchIngredients}
                onSelectIngredient={setIngredients}
              />
            )}
          </div>
          <div style={{ flex: 1 }}>
            <div className={styles.macroSectionTitle}>Total energy:</div>
            <div className={styles.macroContainer}>
              {Object.entries(calculateMacro(ingredients)).map(
                (item, index) => (
                  <div key={index} className={styles.macroItem}>
                    <span className={styles.macroValue}>
                      {item[1]?.toLocaleString(undefined, {
                        maximumFractionDigits: item[0] === "cal" ? 0 : 1,
                      })}
                      {item[0] !== "cal"
                        ? item[0] === "P/E"
                          ? "%"
                          : " g"
                        : ""}
                    </span>
                    <span className={styles.macroTitle}>{item[0]}</span>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        <div className={styles.sectionDivider} />
        <h5 className={styles.sectionTitle}>Method</h5>
        <div className={styles.preparationsList}>
          {preparations.map((item, index) => (
            <div key={index} className={styles.preparationMethod}>
              <Input
                value={item}
                label={`Step ${index + 1}`}
                onChange={(e) => onChangePreparationMethod(e, index)}
                wrapperStyles={styles.preparationMethodInput}
                maxLength={500}
              />
              <TrashIcon
                onClick={() => onDeletePreparationMethod(index)}
                className={styles.preparationMethodDeleteButton}
              />
            </div>
          ))}

          <div
            onClick={() => setPreparations((prev) => [...prev, ""])}
            className={styles.addButton}
          >
            <PlusIcon />
            <span className={styles.title}>Add Method</span>
          </div>
        </div>
        <div className={styles.sectionDivider} />
        <h5 className={styles.sectionTitle}>Tags *</h5>
        <div className={styles.tagsContainer}>
          {isLoadingTags && (
            <div className={styles.tagsLoader}>
              <PulseLoader color={"#7D4AFB"} />
            </div>
          )}
          {!isLoadingTags &&
            allTags?.map((item, index) => {
              const isChecked = selectedTags.includes(item.value);
              return (
                <TagSelector
                  key={`${item}_${index}`}
                  isChecked={isChecked}
                  setIsChecked={() => {
                    if (isChecked) {
                      setSelectedTags((prev) =>
                        prev.filter((tag) => tag !== item.value)
                      );
                    } else {
                      if (selectedTags.length === 10) {
                        return;
                      }
                      setSelectedTags((prev) => [...prev, item.value]);
                    }
                  }}
                  title={item.value}
                />
              );
            })}
        </div>
        <div className={styles.sectionDivider} />
        <footer className={styles.footer}>
          <div className={styles.leftContainer}>
            <Button
              title="Cancel"
              styleType="disabled"
              size="medium"
              backgroundColor="#e4e4e4"
              onClick={() => navigation(-1)}
            />
            <Button
              title="Save"
              styleType={isDisabled ? "disabled" : "filled"}
              onClick={onCreateRecipe}
              size="medium"
              disabled={isDisabled}
              loading={isLoadingSave}
            />
            <Button
              onClick={() => setIsShowPreview(true)}
              title="Preview"
              styleType="filled"
              size="medium"
              backgroundColor="#60C055"
            />
          </div>
          <div className={styles.rightContainer} />
        </footer>
      </div>
    </>
  );
};

export default CreateRecipe;
