import { useNavigate, useParams } from "react-router-dom";
import { AccordionIcon, ArrowLineIcon, PageIcon } from "../../../assets";
import { Button, FaqAccordion, FaqPreview } from "../../../components";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import DragDropList from "../../../components/DragDropList/DragDropList";
import { DragDropChangeValue } from "../../../components/DragDropList/DragDropList.types";
import { moveElementInArray } from "../../../utils";
import { cloneDeep } from "lodash";
import {
  FaqArticleContent,
  useDeleteTopLevelFaqSection,
  useGetFaqItem,
  useUpdateTopLevelFaq,
} from "../../../services/faq.service";
import { PulseLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";
import { DeleteFaqSection } from "../../../modals";
import { useQueryClient } from "@tanstack/react-query";
import { getLinkForUploadImage } from "../../../services/auth.service";
import { uploadFile } from "../../../services/file.service";
import toast from "react-hot-toast";

type FaqArticleContentImage = FaqArticleContent & {
  imageFile?: ArrayBuffer | null | undefined | string | any;
  imageFileName?: string;
};
const EditFaqPage = () => {
  const navigation = useNavigate();

  const params = useParams();
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);

  const { data: faqInfo, isPending } = useGetFaqItem(params?.id || null);

  const queryClient = useQueryClient();
  const [faqContent, setFaqContent] = useState<FaqArticleContentImage[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    mutateAsync: mutateDeleteTopLevelFaqSection,
    isPending: isPendingDelete,
  } = useDeleteTopLevelFaqSection();

  const { mutateAsync: updateTopLevelFaq, isPending: isPendingUpdate } =
    useUpdateTopLevelFaq();

  const onUpdateTopLevelSection = async ({
    status,
  }: {
    status: "draft" | "published";
  }) => {
    try {
      setIsLoading(true);

      const content: FaqArticleContent[] = [];

      for (const item of faqContent) {
        let imageLink = "";
        if (item.imageFile) {
          const resLinkImg = await getLinkForUploadImage(
            item.imageFileName || "",
            "faq"
          );
          imageLink = resLinkImg?.data?.data?.downloadUrl;
          let blob = new Blob([item.imageFile], { type: item.imageFile.type });

          await uploadFile(blob, resLinkImg?.data?.data?.uploadUrl);
        } else if (item?.image) {
          imageLink = item.image;
        }

        content.push({
          ...item,
          image: imageLink,
        });
      }

      if (params?.id) {
        const res = await updateTopLevelFaq({
          id: params?.id,
          content,
          status,
        });
        if (res?.success) {
          queryClient.invalidateQueries({ queryKey: ["top-level-faq"] });

          await queryClient.invalidateQueries({
            queryKey: ["faq-item", faqInfo?.parentId],
          });
          await queryClient.invalidateQueries({
            queryKey: ["faq-item", faqInfo?.id],
          });
        }

        toast.success("Successfully updated");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (params?.id) {
      await mutateDeleteTopLevelFaqSection(params.id);
      if (!faqInfo?.parentId) {
        await queryClient.invalidateQueries({ queryKey: ["top-level-faq"] });
      } else {
        await queryClient.invalidateQueries({
          queryKey: ["faq-item", faqInfo.parentId],
        });
      }
      navigation("/faq");
    }
  };

  useEffect(() => {
    if (faqInfo?.content) {
      setFaqContent(faqInfo?.content);
    }
  }, [faqInfo]);

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return (e.returnValue = "Are you sure you want to close?");
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  const onAddAccordion = () => {
    setFaqContent([
      ...faqContent,
      {
        id: uuidv4(),
        index: faqContent.length,
        type: "accordion",
        title: "",
        html: "",
        image: "",
        imageFile: undefined,
        imageFileName: "",
      },
    ]);
  };

  const onAddText = () => {
    setFaqContent([
      ...faqContent,
      {
        id: uuidv4(),
        index: faqContent.length,
        type: "text",
        title: `Text Section ${faqContent?.length + 1}`,
        html: "",
        image: "",
        imageFile: undefined,
        imageFileName: "",
      },
    ]);
  };

  const onChangeOrder = (value: DragDropChangeValue) => {
    const { fromIndex, toIndex } = value;
    setFaqContent(moveElementInArray(faqContent, fromIndex, toIndex));
  };

  const onDelete = (id: string) => {
    const findIndex = faqContent.findIndex((item) => item.id === id);
    if (findIndex !== -1) {
      setFaqContent((prev) => {
        const newFaqContent = cloneDeep(prev);
        newFaqContent.splice(findIndex, 1);
        return newFaqContent;
      });
    }
  };

  const onChangeContent = (content: string, id: string) => {
    const findIndex = faqContent.findIndex((item) => item.id === id);
    if (findIndex !== -1) {
      setFaqContent((prev) => {
        const newFaqContent = cloneDeep(prev);
        newFaqContent[findIndex].html = content;
        return newFaqContent;
      });
    }
  };

  const onChangeTitle = (title: string, id: string) => {
    const findIndex = faqContent.findIndex((item) => item.id === id);
    if (findIndex !== -1) {
      setFaqContent((prev) => {
        const newFaqContent = cloneDeep(prev);
        newFaqContent[findIndex].title = title;
        return newFaqContent;
      });
    }
  };

  const onChangeImage = (
    {
      imageFile,
      imageFileName,
      image,
    }: {
      imageFile: ArrayBuffer | null | undefined | string | any;
      imageFileName: string;
      image: string;
    },
    id: string
  ) => {
    const findIndex = faqContent.findIndex((item) => item.id === id);
    if (findIndex !== -1) {
      setFaqContent((prev) => {
        const newFaqContent = cloneDeep(prev);
        newFaqContent[findIndex].image = image;
        newFaqContent[findIndex].imageFile = imageFile;
        newFaqContent[findIndex].imageFileName = imageFileName;
        return newFaqContent;
      });
    }
  };

  const renderItem = (item: FaqArticleContent) => {
    return (
      <FaqAccordion
        type={item.type}
        title={item.title}
        html={item.html}
        onChangeTitle={onChangeTitle}
        onChangeContent={onChangeContent}
        onChangeImage={onChangeImage}
        image={item.image}
        index={item.index}
        onDelete={onDelete}
        id={item.id}
      />
    );
  };

  if (isPending) {
    return (
      <div className={styles.loader}>
        <PulseLoader color={"#7D4AFB"} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <DeleteFaqSection
        isOpen={isVisibleDeleteModal}
        setIsOpen={setIsVisibleDeleteModal}
        onDelete={handleDelete}
        isLoading={isPendingDelete}
        title={
          faqInfo?.type === "section"
            ? "Delete nested section?"
            : "Delete article?"
        }
        subtitle={`This action cannot be undone and will delete all nested elements inside it.`}
      />
      <header className={styles.header}>
        <ArrowLineIcon
          onClick={() => navigation(-1)}
          className={styles.headerArrowButton}
        />
        <h5 className={styles.title}>{faqInfo?.title}</h5>
        <div className={styles.headerAddButton}>
          <Button
            title={"Delete"}
            styleType="filled"
            size="small"
            disabled={isLoading || isPendingDelete}
            onClick={() => setIsVisibleDeleteModal(true)}
            style={{ backgroundColor: "#ff0000" }}
          />
          <div className={styles.buttonDivider} />
          <Button
            title={"Draft"}
            onClick={() => onUpdateTopLevelSection({ status: "draft" })}
            styleType="outlined"
            disabled={isLoading || isPendingUpdate}
            size="small"
          />
          <div className={styles.buttonDivider} />

          <Button
            title={"Publish"}
            disabled={isLoading || isPendingUpdate}
            onClick={() => onUpdateTopLevelSection({ status: "published" })}
            styleType="filled"
            size="small"
          />
        </div>
      </header>
      <div className={styles.rowContainer}>
        <section className={styles.contentContainer}>
          <DragDropList
            data={faqContent}
            renderItem={renderItem}
            droppableId="1"
            emptyText="No Content"
            onChange={onChangeOrder}
          />

          <div className={styles.actionButtons}>
            <div onClick={onAddAccordion} className={styles.actionButton}>
              <AccordionIcon /> <span>Add Accordion</span>
            </div>
            <div onClick={onAddText} className={styles.actionButton}>
              <PageIcon /> <span>Add text</span>
            </div>
          </div>
        </section>
        <FaqPreview faqContent={faqContent} />
      </div>
    </div>
  );
};

export default EditFaqPage;
