import Modal from "react-modal";
import { CrossIcon, PlusIcon } from "../../assets";
import styles from "./EditFaqSection.module.scss";
import { Button, Input } from "../../components";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FaqItem, useUpdateTopLevelFaq } from "../../services/faq.service";
import { useFormik } from "formik";
import { createFaqSectionValidationScheme } from "./validation";
import { uploadFile } from "../../services/file.service";
import { getLinkForUploadImage } from "../../services/auth.service";
import { useQueryClient } from "@tanstack/react-query";

type EditFaqSectionProps = {
  isVisible: boolean;
  onClose: () => void;
  item: FaqItem;
};

const EditFaqSection: React.FC<EditFaqSectionProps> = ({
  isVisible,
  onClose,
  item,
}) => {
  const [image, setImage] = useState<string>(item?.image || "");
  const [imageFile, setImageFile] = useState<
    ArrayBuffer | null | undefined | string | any
  >();
  const [imageFileName, setImageFileName] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      name: item?.title || "",
    },
    validationSchema: createFaqSectionValidationScheme,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: (values) => {
      onUpdateTopLevelSection({
        name: values.name,
      });
    },
  });

  const inputFile = useRef<HTMLInputElement>(null);

  const showOpenFileDialog = () => {
    inputFile.current?.click();
  };

  useEffect(() => {
    if (!isVisible) {
      resetForm();
    }
  }, [isVisible]);

  const { mutateAsync: updateTopLevelFaq, isPending: isPendingUpdate } =
    useUpdateTopLevelFaq();

  const onUpdateTopLevelSection = async ({ name }: { name: string }) => {
    try {
      setIsLoading(true);
      let imageLink = "";
      if (imageFile) {
        const resLinkImg = await getLinkForUploadImage(imageFileName, "faq");
        imageLink = resLinkImg?.data?.data?.downloadUrl;
        let blob = new Blob([imageFile], { type: imageFile.type });

        await uploadFile(blob, resLinkImg?.data?.data?.uploadUrl);
      }

      if (item?.image) {
        imageLink = item.image;
      }

      const res = await updateTopLevelFaq({
        id: item.id,
        title: name,
        image,
      });
      if (res?.success) {
        queryClient.invalidateQueries({ queryKey: ["top-level-faq"] });

        await queryClient.invalidateQueries({
          queryKey: ["faq-item", item.parentId],
        });
        onClose();
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      setImage(URL.createObjectURL(e.target.files[0]));
      let file = e.target.files[0];

      setImageFile(e.target.files[0]);

      setImageFileName(file?.name);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={isVisible}
      shouldFocusAfterRender={false}
      onRequestClose={onClose}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <h5 className={styles.title}>Edit element</h5>

        <CrossIcon onClick={onClose} className={styles.closeBtn} />
        <Input
          label="Name"
          placeholder="Name"
          value={values.name}
          onChange={handleChange("name")}
          onBlur={handleBlur("name")}
          error={touched.name ? errors.name : ""}
        />
        <div onClick={showOpenFileDialog} className={styles.addContainer}>
          {image ? (
            <img src={image} className={styles.addButton} alt="image" />
          ) : (
            <div className={styles.addButton}>
              <PlusIcon />
            </div>
          )}
          <input
            style={{ display: "none" }}
            ref={inputFile}
            type={"file"}
            accept="image/*"
            onChange={onSelectFile}
            onClick={(e: any) => (e.target.value = null)}
          />
          <span>Add icon (optional)</span>
        </div>
        <Button
          title="Save"
          size="medium"
          type="submit"
          loading={isLoading || isPendingUpdate}
          onClick={() => handleSubmit()}
          styleType="filled"
        />
      </div>
    </Modal>
  );
};

export default EditFaqSection;
