import Modal from "react-modal";
import { CrossIcon, NestedIcon, PageIcon, PlusIcon } from "../../assets";
import styles from "./styles.module.scss";
import { Button, Input, Tabs } from "../../components";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useCreateTopLevelFaq } from "../../services/faq.service";
import { useFormik } from "formik";
import { createFaqSectionValidationScheme } from "./validation";
import { uploadFile } from "../../services/file.service";
import { getLinkForUploadImage } from "../../services/auth.service";
import { useQueryClient } from "@tanstack/react-query";

type EditFaqSectionProps = {
  isVisible: boolean;
  onClose: () => void;
  parentId?: string;
};

const CreateFaqSection: React.FC<EditFaqSectionProps> = ({
  isVisible,
  onClose,
  parentId,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [image, setImage] = useState<string>("");
  const [imageFile, setImageFile] = useState<
    ArrayBuffer | null | undefined | string | any
  >();
  const [imageFileName, setImageFileName] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: createFaqSectionValidationScheme,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: (values) => {
      onCreateTopLevelSection({
        name: values.name,
        type: selectedTabIndex === 0 ? "section" : "article",
      });
    },
  });

  const inputFile = useRef<HTMLInputElement>(null);

  const showOpenFileDialog = () => {
    inputFile.current?.click();
  };

  useEffect(() => {
    if (!isVisible) {
      resetForm();
    }
  }, [isVisible]);

  const { mutateAsync: createTopLevelFaq, isPending } = useCreateTopLevelFaq();

  const onCreateTopLevelSection = async ({
    name,
    type,
  }: {
    name: string;
    type: "article" | "section";
  }) => {
    try {
      setIsLoading(true);
      let imageLink = "";
      if (imageFile) {
        const resLinkImg = await getLinkForUploadImage(imageFileName, "faq");
        imageLink = resLinkImg?.data?.data?.downloadUrl;
        let blob = new Blob([imageFile], { type: imageFile.type });

        await uploadFile(blob, resLinkImg?.data?.data?.uploadUrl);
      }

      const res = await createTopLevelFaq({
        title: name,
        type,
        image: imageLink,
        parentId,
      });
      if (res?.success) {
        if (!parentId) {
          queryClient.invalidateQueries({ queryKey: ["top-level-faq"] });
        }

        queryClient.invalidateQueries({ queryKey: ["faq-item", parentId] });

        onClose();
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      setImage(URL.createObjectURL(e.target.files[0]));
      let file = e.target.files[0];

      setImageFile(e.target.files[0]);

      setImageFileName(file?.name);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={isVisible}
      shouldFocusAfterRender={false}
      onRequestClose={onClose}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <h5 className={styles.title}>New element</h5>
        <Tabs
          tabs={[
            { title: "Nested section", icon: () => <NestedIcon /> },
            { title: "Article", icon: () => <PageIcon /> },
          ]}
          selectedTabIndex={selectedTabIndex}
          onTabChange={setSelectedTabIndex}
        />
        <CrossIcon onClick={onClose} className={styles.closeBtn} />
        <Input
          label="Name"
          placeholder="Name"
          value={values.name}
          onChange={handleChange("name")}
          onBlur={handleBlur("name")}
          error={touched.name ? errors.name : ""}
        />
        <div onClick={showOpenFileDialog} className={styles.addContainer}>
          {image ? (
            <img src={image} className={styles.addButton} alt="image" />
          ) : (
            <div className={styles.addButton}>
              <PlusIcon />
            </div>
          )}
          <input
            style={{ display: "none" }}
            ref={inputFile}
            type={"file"}
            accept="image/*"
            onChange={onSelectFile}
            onClick={(e: any) => (e.target.value = null)}
          />
          <span>Add icon (optional)</span>
        </div>
        <Button
          title="Create"
          size="medium"
          type="submit"
          loading={isLoading || isPending}
          onClick={() => handleSubmit()}
          styleType="filled"
        />
      </div>
    </Modal>
  );
};

export default CreateFaqSection;
