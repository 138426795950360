import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import isEmpty from "lodash/isEmpty";
import ClipLoader from "react-spinners/ClipLoader";

import { DragDropListProps } from "./DragDropList.types";

import styles from "./DragDropList.module.scss";

const DragDropList = <T extends { id: string }>({
  isLoading,
  droppableId,
  data,
  emptyText = "No categories yet",
  isDragDisabled = false,
  onChange,
  renderItem,
}: DragDropListProps<T>) => {
  const onDragEnd = (dropResult: DropResult) => {
    const { destination, source, draggableId } = dropResult;

    if (!destination) {
      return;
    }

    const { index: fromIndex } = source;
    const { index: toIndex } = destination;

    if (fromIndex !== toIndex) {
      onChange({ draggableId, fromIndex, toIndex });
    }
  };

  if (isLoading) {
    return (
      <div className={styles["drag-drop-list__content"]}>
        <ClipLoader size={30} color="#fb3973" />
      </div>
    );
  }

  if (isEmpty(data)) {
    return <div className={styles["drag-drop-list__content"]}>{emptyText}</div>;
  }

  return (
    <div className={styles["drag-drop-list"]}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              className={styles["drag-drop-list__container"]}
              {...provided.droppableProps}
            >
              {data.map((item: T, index: number) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {renderItem(item)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DragDropList;
