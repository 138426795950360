import { cloneDeep } from "lodash";

export const moveElementInArray = <T>(
  array: T[],
  fromIndex: number,
  toIndex: number
) => {
  const copyArray = cloneDeep(array);
  const [elementToMove] = copyArray.splice(fromIndex, 1);
  copyArray.splice(toIndex, 0, elementToMove);

  return copyArray;
};

export const replaceElementInArray = <T>(
  array: T[],
  data: T,
  index: number
) => {
  const copyArray = [...array];
  copyArray.splice(index, 1, data);

  return copyArray;
};
