import React, { FC } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import styles from "./styles.module.scss";
import { uploadFile } from "../../services/file.service";
import clsx from "clsx";
import { getLinkForUploadImage } from "../../services/auth.service";
import Quill from "quill";
// @ts-ignore
import ImageResize from "@taoqf/quill-image-resize-module";
// @ts-ignore

import ImageUploader from "quill-image-uploader";

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);

const saveToServer = async (file: any) => {
  try {
    const resLinkImg = await getLinkForUploadImage(file?.name, "faq");
    let blob = new Blob([file], { type: file.type });
    await uploadFile(blob, resLinkImg?.data?.data?.uploadUrl);
    return resLinkImg?.data?.data?.downloadUrl;
  } catch (error) {
    console.log("error", error);
  }
};

const DEFAULT_MODULES = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ color: [] }, { background: [] }],

    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["link", "image", "video"],
    [{ color: [] }, { background: [] }],

    ["clean"],
  ],
  imageUploader: {
    upload: (file: any) => {
      return saveToServer(file);
    },
  },
  imageResize: {
    modules: ["Resize", "DisplaySize", "Toolbar"],
  },

  clipboard: {
    matchVisual: false,
  },
};

const WITH_DISABLED_MODULES = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],

    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["link"],
    [{ color: [] }, { background: [] }],

    ["clean"],
  ],
  imageUploader: {
    upload: (file: any) => {
      return saveToServer(file);
    },
  },
  clipboard: {
    matchVisual: false,
  },
};

const DEFAULT_FORMATS = [
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "list",
  "indent",
  "size",
  "header",
  "link",
  "image",
  "video",
  "color",
  "background",
  "clean",
  "imageBlot",
];

const WITH_DISABLED_FORMATS = [
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "list",
  "indent",
  "size",
  "header",
  "link",
  "color",
  "background",
  "clean",
  "imageBlot",
];

type RichTextEditorProps = {
  initialValue: string;
  setValue: (value: string) => void;
  disableMedia?: boolean;
};

const RichTextEditor: FC<RichTextEditorProps> = ({
  initialValue = ``,
  setValue,
  disableMedia = false,
}) => {
  const theme = "snow";
  const { quill, quillRef } = useQuill({
    theme,
    modules: !disableMedia ? DEFAULT_MODULES : WITH_DISABLED_MODULES,
    formats: !disableMedia ? DEFAULT_FORMATS : WITH_DISABLED_FORMATS,
  });

  React.useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(initialValue);
      quill.on("text-change", () => {
        setValue(quill.root.innerHTML);
      });
    }
  }, [quill]);

  return (
    <div className={clsx(styles.wrapper)}>
      <div ref={quillRef} />
    </div>
  );
};

export default RichTextEditor;
