import { FC, useEffect, useState } from "react";
import { PlusIcon } from "../../assets";
import styles from "./styles.module.scss";
import {
  FaqItem,
  useChangeOrderFaqItems,
  useGetFaqItem,
} from "../../services/faq.service";
import FaqCard from "../FaqCard";
import { CreateFaqSection } from "../../modals";
import { useNavigate } from "react-router-dom";
import DragDropList from "../DragDropList/DragDropList";
import { DragDropChangeValue } from "../DragDropList/DragDropList.types";
import { moveElementInArray } from "../../utils";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
  title: string;
  items: FaqItem[];
  id: string | null;
};

const FaqSection: FC<Props> = ({ title, items, id }) => {
  const [isVisibleEditFaqSection, setIsVisibleEditFaqSection] = useState(false);
  const [selectedSection, setSelectedSection] = useState<FaqItem | null>(null);
  const [faqItems, setFaqItems] = useState<FaqItem[]>(items);

  const { data: selectedFaqItem } = useGetFaqItem(selectedSection?.id || null);

  const { mutateAsync: changeOrderFaqItems } = useChangeOrderFaqItems();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const onSelectCard = (item: FaqItem) => {
    if (item?.type === "article") {
      navigate(`/faq/${item.id}`);
    } else if (item.id === selectedSection?.id) {
      setSelectedSection(null);
    } else {
      setSelectedSection(item);
    }
  };

  const onChangeOrder = async ({ fromIndex, toIndex }: DragDropChangeValue) => {
    const newFaqItems = moveElementInArray(faqItems, fromIndex, toIndex);
    setFaqItems(newFaqItems);
    await changeOrderFaqItems({
      parentId: id || undefined,
      ordering: newFaqItems.map((item) => item.id),
    });
    if (id) {
      queryClient.invalidateQueries({ queryKey: ["faq-item", id] });
    } else {
      queryClient.invalidateQueries({ queryKey: ["top-level-faq"] });
    }
  };

  useEffect(() => {
    setFaqItems(items);
  }, [items]);

  const renderItem = (item: FaqItem) => {
    return (
      <FaqCard
        key={item.id}
        item={item}
        isSelected={selectedSection?.id === item.id}
        onClick={onSelectCard}
      />
    );
  };

  return (
    <>
      <CreateFaqSection
        isVisible={isVisibleEditFaqSection}
        onClose={() => setIsVisibleEditFaqSection(false)}
        parentId={id || undefined}
      />
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h6>{title}</h6>

          <div
            onClick={() => setIsVisibleEditFaqSection(true)}
            className={styles.plusButton}
          >
            <PlusIcon />
          </div>
        </div>
        <div className={styles.contentContainer}>
          <DragDropList
            data={faqItems}
            droppableId={id || "0"}
            onChange={onChangeOrder}
            renderItem={renderItem}
            emptyText="No elements yet"
          />
        </div>
      </div>
      {selectedFaqItem?.children && selectedFaqItem?.id ? (
        <FaqSection
          key={selectedFaqItem.id}
          items={selectedFaqItem?.children}
          title={selectedFaqItem.title}
          id={selectedFaqItem.id}
        />
      ) : null}
    </>
  );
};

export default FaqSection;
