import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLineIcon, UserIcon } from "../../../assets";
import { Button } from "../../../components";
import clsx from "clsx";
import ProgressTab from "./components/ProgressTab";
import PostsTab from "./components/PostsTab";
import ActiveGroup from "./components/ActiveGroup";
import toast from "react-hot-toast";
import {
  deleteUser,
  getUserById,
  unBlockUser,
} from "../../../services/users.service";
import { IUser } from "../../../models";
import { PulseLoader } from "react-spinners";
import { BlockUser, DeleteUser } from "../../../modals";

const UserProfile: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams();

  const [activeTab, setActiveTab] = useState<"Progress" | "Posts" | "Groups">(
    "Progress"
  );
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [userProfile, setUserProfile] = useState<IUser>();
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isShowBlockModal, setIsShowBlockModal] = useState(false);

  const [isLoadingUnblock, setIsLoadingUnblock] = useState(false);

  const onGetUserProfile = async (id: string) => {
    try {
      setIsLoadingProfile(true);
      const { data } = await getUserById(id);
      if (data?.success) {
        setUserProfile(data?.data);
      }
    } catch (error) {
      toast.error(error?.message?.[0]?.message || "Something went wrong");
    } finally {
      setIsLoadingProfile(false);
    }
  };

  const onDeleteUser = async (id: number) => {
    try {
      setIsLoadingDelete(true);

      const { data } = await deleteUser(id);
      if (data?.success) {
        toast.success("User was successfully deleted");
        navigation(-1);
      }
    } catch (error) {
      toast.error(error?.message?.[0]?.message || "Something went wrong");
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const onUnblockUser = async (id: number) => {
    try {
      setIsLoadingUnblock(true);
      const { data } = await unBlockUser(id);
      if (data?.success) {
        toast.success("User was successfully unblocked");
        onGetUserProfile(id?.toString());
      }
    } catch (error) {
      toast.error(error?.message?.[0]?.message || "Something went wrong");
    } finally {
      setIsLoadingUnblock(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      onGetUserProfile(params.id);
    }
  }, [params?.id]);

  return (
    <>
      <BlockUser
        isOpen={isShowBlockModal}
        setIsOpen={setIsShowBlockModal}
        userId={userProfile?.id || 0}
        onGetUserInfo={() => onGetUserProfile(params?.id || "")}
      />
      <DeleteUser
        isOpen={isShowDelete}
        setIsOpen={setIsShowDelete}
        onDelete={() => onDeleteUser(userProfile?.id || 0)}
        isLoading={isLoadingDelete}
        title="Are you sure you want to delete this user?"
      />
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <ArrowLineIcon
            onClick={() => navigation(-1)}
            className={styles.headerArrowButton}
          />
          <h5 className={styles.headerTitle}>User Profile</h5>
          <div className={styles.headerButtonsContainer}>
            <Button
              title={userProfile?.blockReason ? "Unblock" : "Block"}
              styleType={"disabled"}
              size="small"
              customStyles={styles.headerButton}
              loading={isLoadingUnblock}
              onClick={() => {
                if (userProfile?.blockReason) {
                  onUnblockUser(userProfile?.id || 0);
                } else {
                  setIsShowBlockModal(true);
                }
              }}
            />
            <Button
              title="Delete"
              styleType="filled"
              backgroundColor="#FF3C3C"
              size="small"
              customStyles={styles.headerButton}
              onClick={() => setIsShowDelete(true)}
            />
          </div>
        </header>
        {isLoadingProfile || !userProfile ? (
          <div className={styles.loaderWrapper}>
            <PulseLoader color={"#7D4AFB"} />
          </div>
        ) : (
          <div className={styles.contentContainer}>
            <div className={styles.userInfoContainer}>
              <div className={styles.userAvatar}>
                {userProfile?.image ? (
                  <img src={userProfile.image} className={styles.avatar} />
                ) : (
                  <UserIcon />
                )}
              </div>
              <div className={styles.userBlock}>
                <p className={styles.userName}>
                  {userProfile?.firstName} {userProfile?.lastName}
                </p>
                {userProfile?.referral ? (
                  <p className={styles.referral}>
                    Referral: {userProfile?.referral}
                  </p>
                ) : null}
              </div>
            </div>

            <div className={styles.tabButtonsContainer}>
              <div
                onClick={() => setActiveTab("Progress")}
                className={clsx(styles.tabButton, {
                  [styles.tabButtonActive]: activeTab === "Progress",
                })}
              >
                <span
                  className={clsx(styles.tabButtonTitle, {
                    [styles.tabButtonTitleActive]: activeTab === "Progress",
                  })}
                >
                  Progress
                </span>
              </div>

              <div
                onClick={() => setActiveTab("Posts")}
                className={clsx(styles.tabButton, {
                  [styles.tabButtonActive]: activeTab === "Posts",
                })}
              >
                <span
                  className={clsx(styles.tabButtonTitle, {
                    [styles.tabButtonTitleActive]: activeTab === "Posts",
                  })}
                >
                  Posts
                </span>
                <div
                  className={clsx(styles.tabButtonCounterWrapper, {
                    [styles.tabButtonCounterWrapperActive]:
                      activeTab === "Posts",
                  })}
                >
                  <span>0</span>
                </div>
              </div>
              <div
                onClick={() => setActiveTab("Groups")}
                className={clsx(styles.tabButton, {
                  [styles.tabButtonActive]: activeTab === "Groups",
                })}
              >
                <span
                  className={clsx(styles.tabButtonTitle, {
                    [styles.tabButtonTitleActive]: activeTab === "Groups",
                  })}
                >
                  Active Groups
                </span>
                <div
                  className={clsx(styles.tabButtonCounterWrapper, {
                    [styles.tabButtonCounterWrapperActive]:
                      activeTab === "Groups",
                  })}
                >
                  <span>0</span>
                </div>
              </div>
            </div>
            {activeTab === "Progress" && params.id && (
              <ProgressTab userInfo={userProfile} />
            )}
            {activeTab === "Posts" && params.id && (
              <PostsTab userId={params.id} />
            )}
            {activeTab === "Groups" && params.id && (
              <ActiveGroup userId={params.id} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UserProfile;
