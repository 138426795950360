import styles from "./styles.module.scss";

type TabsProps = {
  tabs: Array<{
    title: string;
    icon: () => JSX.Element;
  }>;
  selectedTabIndex: number;
  onTabChange: (tabIndex: number) => void;
};

const Tabs: React.FC<TabsProps> = ({ tabs, selectedTabIndex, onTabChange }) => {
  return (
    <div className={styles.tabs}>
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={
            index === selectedTabIndex ? styles.selectedTab : styles.tab
          }
          onClick={() => onTabChange(index)}
        >
          {tab?.icon?.()}
          <span>{tab.title}</span>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
